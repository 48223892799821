const UserAccess = {
  Dashboard: "DASHBOARD",
  Pricing: "PRICING",
  FleetPrices: "FLEET_PRICES",
  PricingChanges: "PRICING_CHANGES",
  DoxNegotiatedDiscounts: "DOX_NEGOTIATED_DISCOUNT",
  Payments: "PAYMENTS",
  SettlementHistory: "SETTLEMENT_HISTORY",
  PremiumPayout: "PREMIUM_PAYMENT",
  Accounts: "ACCOUNTS",
  FleetAccounts: "FLEET_ACCOUNTS",
  CustomersList: "CUSTOMERS_LIST",
  StationAccounts: "STATION_ACCOUNT",
  CampaignsPrograms: "CAMPAIGNS_PROGRAMS",
  VoucherCampaign: "CAMPAIGNS",
  Vouchers: "VOUCHERS",
  ConsumerOtherServices: "CONSUMER_OTHER_SERVICES",
  LubeServOnWheelsBookings: "LUBESERV_ON_WHEELS_BOOKINGS",
  LubeServOnSiteBookings: "LUBESERV_ON_SITE_BOOKINGS",
  AccessAuthorization: "ACCESS_AUTHORIZATION",
  VIPTransactionHistory: "VIP_TRANSACTION_HISTORY",
  UserAccess: "USER_ACCESS",
  PLCCashIn: "PLC_CASH_IN",
  PLBCashIn: "PLB_CASH_IN",
  PLCRedemption: "PLC_REDEMPTION",
  PLCLOCQPayForFuel: "PLC_LOCQPAY_FOR_FUEL",
  PLBPayWithLOCQPayRedemption: "PLB_PAY_WITH_LOCQPAY_REDEMPTION",
  PLBRedemption: "PLB_REDEMPTION",
  PriceBuildUp: "PRICE_BUILD_UP",
  CardManagementList: "CARD_MANAGEMENT",
  KycSubmissions: "KYC_SUBMISSION",
  PlcRFIDLoading: "PLC_RFID_LOADING",
};

export default UserAccess;
