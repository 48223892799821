import {
  prettifyAwardCategory,
  prettifyProduct,
  prettifyVipTransactionHistoryStatus,
} from "utils/pretty.utils";
import { formatAmount, formatNumber } from "utils";
import moment from "moment";
import { DateTime, Products } from "enums";
import { Pill, PopOver, PopOverMenu, Product } from "components/commons";
import { VIPAwardPointsStatusEnum } from "enums/vip-transaction-history-status";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import styles from "./transaction-history.module.scss";
import locale from "localization";

export const mapDataToList = ({ pointsHistory, onOptionClickVoid, getUserAccess }) => {
  const awardPoint = transactionAwardPointsType(pointsHistory);

  const {
    cardNumber,
    points,
    transactionId,
    orNumber,
    amount,
    station,
    productCode,
    mobileNumber,
    createdAt,
    awardType,
    status,
    remarks,
  } = awardPoint;

  const options = [];
  if (getUserAccess?.fullAccess || getUserAccess?.voidAccess) {
    if (status !== VIPAwardPointsStatusEnum.Voided) {
      options.push({
        content: locale.void,
        onClick: () => {
          onOptionClickVoid?.(pointsHistory);
        },
      });
    }
  }

  const styleCell = {
    wordBreak: "break-all",
  };

  const print = (val) => {
    return val ? val : "--";
  };

  return {
    vipTransactionId: (
      <div
        style={{
          ...styleCell,
          width: "80px",
        }}
      >
        {print(transactionId)}
      </div>
    ),
    nameAndMobileNumber: (
      <div
        style={{
          ...styleCell,
          width: "110px",
        }}
      >
        {print(mobileNumber)}
      </div>
    ),
    vipCardNumber: (
      <div
        style={{
          ...styleCell,
          width: "120px",
        }}
      >
        {print(cardNumber)}
      </div>
    ),

    station: (
      <div
        style={{
          ...styleCell,
          width: "150px",
        }}
      >
        {print(station)}
      </div>
    ),
    productType: (
      <div
        style={{
          ...styleCell,
          width: "120px",
        }}
      >
        <Product
          grass={productCode === Products.Gas91}
          salsa={productCode === Products.Gas95}
          deepBlue={productCode === Products.Gas97}
          cheddar={productCode === Products.Diesel}
          purple={!Object.values(Products).includes(productCode)}
        >
          {prettifyProduct(print(productCode))}
        </Product>
      </div>
    ),
    transactionAmount: (
      <div
        style={{
          ...styleCell,
          width: "100px",
        }}
      >
        {formatAmount(amount ?? 0)}
      </div>
    ),
    pointsEarned: (
      <div
        style={{
          ...styleCell,
          width: "100px",
        }}
      >
        {formatNumber(points, 2)} pts
      </div>
    ),
    orNumber: (
      <div
        style={{
          ...styleCell,
          width: "70px",
        }}
      >
        {print(orNumber)}
      </div>
    ),
    awardType: (
      <div
        style={{
          ...styleCell,
          width: "70px",
        }}
      >
        {prettifyAwardCategory(awardType)}
      </div>
    ),
    dateOfTransaction: (
      <div
        style={{
          ...styleCell,
          width: "80px",
        }}
      >
        {moment(createdAt).format(DateTime.K)}
      </div>
    ),
    status: status && (
      <Pill
        grass={status === VIPAwardPointsStatusEnum.Success}
        cement={status === VIPAwardPointsStatusEnum.Voided}
        style={{
          ...styleCell,
          width: "80px",
        }}
      >
        {prettifyVipTransactionHistoryStatus(print(status))}
      </Pill>
    ),
    remarks: remarks && (
      <PopOver content={<div className={styles.remarks}>{remarks}</div>}>
        <div
          className="link"
          style={{
            ...styleCell,
            width: "50px",
          }}
        >
          <b>{locale.view}</b>
        </div>
      </PopOver>
    ),
    action: (
      options.length > 0 &&
      <PopOverMenu options={options}>
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { awardTypes, referenceStation, ...fs } = filterState;

  if (referenceStation.value.length > 0) {
    fs.stationIds = referenceStation.value.map(({ value }) => value).join(",");
  }

  if (referenceStation.isSelectedAll) {
    fs.stationIds = undefined;
  }

  return {
    ...fs,
  };
};

export const transactionAwardPointsType = (responseData) => {
  return {
    pointsHistoryId: responseData?.pointsHistoryId,
    userId: responseData?.userId,
    fleetId: responseData?.fleetId,
    name: responseData?.name,
    cardNumber: responseData?.cardNumber,
    points: responseData?.points,
    awardType: responseData?.awardType,
    transactionId: responseData?.pointsHistoryId,
    productCode: responseData?.productCode,
    stationId: responseData?.stationId,
    mobileNumber: responseData?.mobileNumber,
    amount: responseData?.amount,
    finalAmount: responseData?.finalAmount,
    rewardRedeemed: responseData?.rewardRedeemed,
    createdAt: responseData?.createdAt,

    //
    station: responseData?.station?.name,
    orNumber: responseData?.transactionId,
    status: responseData?.status,
    remarks: responseData?.remarks,
  };
};
