import { Text } from "components/commons";
import locale from "localization";

export const redeemHistoryColumns = [
  {
    key: "vipTransactionId",
    text: <Text style={{ width: "100px" }}>{locale.vipTransactionId}</Text>,
    style: {
      width: "100px",
    },
    width: "5.21%", // 100 / 1920 * 100
  },
  {
    key: "nameAndMobileNumber",
    text: <Text style={{ width: "80px" }}>{locale.nameAndMobileNumber}</Text>,
    width: "5.21%", // 100px for consistency, 100 / 1920 * 100
  },
  {
    key: "vipCardNumber",
    text: <Text style={{ width: "50px" }}>{locale.vipCardNumber}</Text>,
    style: {
      width: "100px", // no width specified before
    },
    width: "5.21%", // keeping 100px as others
  },
  {
    key: "station",
    text: <Text style={{ width: "100px" }}>SEAOIL {locale.stationBranch}</Text>,
    style: {
      width: "150px",
    },
    width: "7.81%", // 150 / 1920 * 100
  },
  {
    key: "pointsEarned",
    text: <Text style={{ width: "50px" }}>{locale.pointsRedeemed}</Text>,
    style: {
      width: "70px",
    },
    width: "3.65%", // 70 / 1920 * 100
  },
  {
    key: "rewardsRedeemed",
    text: <Text style={{ width: "50px" }}>{locale.rewardsRedeemed}</Text>,
    style: {
      width: "70px",
    },
    width: "3.65%", // 70 / 1920 * 100
  },
  {
    key: "dateOfTransaction",
    text: <Text style={{ width: "90px" }}>{locale.dateOfTransaction}</Text>,
    style: {
      width: "90px",
    },
    width: "4.69%", // 90 / 1920 * 100
  },
  {
    key: "status",
    text: locale.status,
    style: {
      width: "50px",
    },
    width: "2.60%", // 50 / 1920 * 100
  },
  {
    key: "remarks",
    text: locale.remarks,
    style: {
      width: "50px",
    },
    width: "2.60%", // 50 / 1920 * 100
  },
  {
    key: "action",
    text: `${locale.action}`,
    style: {
      minWidth: "120px",
    },
    width: "6.25%", // 120 / 1920 * 100
  },
];
