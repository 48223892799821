import React, { useContext } from "react";

import { Intro } from "components/commons";
import useDataTable from "hooks/useDataTable";
import locale from "localization";

import { mapDataToList, mapFilterToRequest } from "./rfid.mapper";
import { rfidFilterState } from "./rfid-filter.state";
import { getRFID } from "apis/rfid.api";
import { rfidColumns } from "./rfid-columns";
import RFIDFilter from "./rfid-filter";
import DataTableV2 from "components/commons/data-table/data-table-v2";

import { SuccessModal } from "components/modals";
import useExport from "hooks/useExport";
import { generateReportAdmin } from "apis";
import { UserContext } from "contexts";

const RFIDModule = () => {
  const { sessionId } = useContext(UserContext);

  const { filter, search, table } = useDataTable({
    api: { api: getRFID },
    filter: { initialState: rfidFilterState(), mapper: mapFilterToRequest },
    table: { key: "rfidTopups", mapper: mapDataToList, columns: rfidColumns },
  });

  const exports = useExport({
    api: generateReportAdmin,
    hasModal: true,
    reportType: "rfid-topup",
    mappedFilterState: {
      ...filter?.mappedFilterState,
      rfidStatuses: filter?.mappedFilterState?.status,
      searchKey: search.searchKey,
      sessionId,
    },
  });

  return (
    <>
      <SuccessModal {...exports?.successModalComponent} />
      <div>
        <Intro
          // actionText={locale.export}
          // actionOnClick={() => {}}
          title={locale.rfidReloading}
          subtitle={locale.viewAndTrackRFIDReloadTransaction}
        />
      </div>
      <RFIDFilter {...filter} {...search} {...exports} />
      <DataTableV2 {...table} paginationV2={true} />
    </>
  );
};

export default RFIDModule;
