import { ApiPath } from "enums";
import { Post, Delete, Put, Get } from "utils";

export const generatePricingReport = async (query) => {
  const res = await Post(`${ApiPath.GeneratePricingReport}`, query);
  return res.data;
};

export const generatePricingReportv2 = async (query) => {
  const res = await Post(`${ApiPath.GeneratePricingReportv2}`, {
    ...query,
  });
  return res.data;
};

export const generateReportAdmin = async (query) => {
  const res = await Post(`${ApiPath.GenerateReportAdmin}`, {
    ...query,
  });
  return res.data;
};

export const generateReportAdminLoyalty = async (query) => {
  const res = await Post(`${ApiPath.GenerateReportAdminLoyalty}`, {
    ...query,
  });
  return res.data;
};

export const updateReport = async ({ reportId, ...body }) => {
  const res = await Put(`${ApiPath.UpdateReport(reportId)}`, body);
  return res.data;
};

export const deleteReport = async ({ reportId }) => {
  const res = await Delete(`${ApiPath.UpdateReport(reportId)}`);
  return res;
};

export const generateReportList = async (query) => {
  const res = await Get(`${ApiPath.GenerateReportList}`, query);
  return res.data;
};

export const generateBookingReport = async (query) => {
  const res = await Post(`${ApiPath.GenerateBookingReport}`, {
    ...query,
  });
  return res.data;
};

export const generateProfileAdmin = async (query) => {
  const res = await Post(`${ApiPath.GenerateProfileAdmin}`, {
    ...query,
    connectionId: query.sessionId,
  });
  return res.data;
};
