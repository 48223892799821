import { Field, TextField } from "components/commons";
import { ConfirmModal } from "components/modals";
import locale from "localization";
import styles from "./void-vip-transaction-history-modal.module.scss";
import { useApi, useForm } from "hooks";
import { useEffect, useMemo } from "react";
import Validation from "utils/validation.utils";
import { voidTransactionHistoryApi } from "apis";

const VoidVIPTransactionHistoryModal = (voidModal = { onSuccess: () => 0 }) => {
  const form = useMemo(() => {
    return {
      remarks: {
        value: "",
        required: true,
        validations: [Validation.required({ noMessage: true })],
      },
    };
  }, []);

  const { fields, modifyField, isFormSubmittable } = useForm({
    initialState: form,
  });

  const { request: voidTransactionHistory, loading: isVoiding } = useApi({
    api: voidTransactionHistoryApi,
  });

  const handleVoidProceed = async () => {
    if (!voidModal?.pointsHistoryId) {
      return voidModal.close();
    }

    const resp = await voidTransactionHistory({
      pointsHistoryId: voidModal?.pointsHistoryId,
      remarks: fields?.remarks.value,
    });

    if (resp) {
      voidModal.close();
      voidModal?.onSuccess?.();
    }
  }
  
  useEffect(() => {
    if (!voidModal?.active) {
      modifyField("remarks", { value: "" })
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voidModal?.active])
  return (
    <ConfirmModal
      {...voidModal}
      loading={isVoiding}
      disabled={isVoiding}
      closable={!isVoiding}
      primary={{
        ...voidModal.primary,
        disabled: isVoiding || !isFormSubmittable,
        loading: isVoiding,
        onClick: handleVoidProceed,
      }}
    >
      <Field
        label={locale.reasonRemarks}
        labelPosition={"left"}
        className={styles.reasonRemarks}
        {...fields?.remarks}
      >
        <TextField
          {...fields?.remarks}
          multiline
          value={fields?.remarks?.value}
          onChange={(_, { value }) => modifyField("remarks", { value })}
        />
      </Field>
    </ConfirmModal>
  );
};

export default VoidVIPTransactionHistoryModal;
