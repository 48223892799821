const DateTime = {
  A: "D MMM YYYY",
  B: "h:mma",
  C: "YYYY-MM-DDT00:00:00ZZ",
  D: "YYYY-MM-DDT23:59:59ZZ",
  E: "MMM DD, YYYY",
  F: "MMMM YYYY",
  G: "MMM DD, YYYY h:mma",
  H: "ddd MM/DD/YYYY h:mm A",
  I: "MMMM",
  J: "YYYY",
  K: "DD MMM YYYY hh:mm A",
  L: "h:mm A",
  M: "HH:mm:ss",
  N: "YYYY-MM-DDTHH:mm:ssZZ",
  O: "YYYY-MM-DD",
  P: "MMMM DD, YYYY hh:mmA",
  Q: "MMM DD, YYYY hh:mma",
};

export default DateTime;
