import {
  CampaignStatus,
  Products,
  VoucherCampaignCurrency,
  VoucherCampaignType,
  SettlementStatus,
  AuditTrailAction,
  AccountStatus,
  ReportType,
  StationStatus,
  PLCStatus,
  PLBStatus,
  FleetAccountType,
  UserStatus,
  LubeServSite,
  CashInChannel,
  CashInStatus,
  CustomerStatus,
  Currency,
  PayWithLOCQPayStatus,
  RedemptionStatus,
  PayWithPesoStatus,
  VoidPaymentValue,
  VoidTransactionValue,
  DeactivateCardValue,
  DispenseType,
  VipType,
  CustomerStatusRemarks,
  AttributeUploadResponse,
  KYCSubmissionStatus,
  RFIDStatus,
  RFIDType,
} from "enums";
import OnboardStatus from "enums/onboard-status";
import PlatformType from "enums/platform-type";
import VoucherStatus from "enums/voucher-status";
import OilChangeType from "enums/oil-change-type";
import Role from "enums/role";
import locale from "localization";
import VoucherCampaignGroup from "enums/voucher-campaign-group";
import ClaimMethod from "enums/claim-method";
import VoucherTypeOptions from "enums/voucher-type-options";
import VoucherType from "enums/voucher-type";
import VoucherTypeSetting from "enums/voucher-type-setting";
import VIPStatus from "enums/vip-status";
import { VIPAwardPointsStatusEnum } from "enums/vip-transaction-history-status";
import { capitalize } from "./text.utils";
import RFIDPaymentMethod from "enums/rfid-payment-method";

export const prettifyProduct = (productCode) => {
  return (
    {
      [Products.Gas91]: locale.populate(locale.gasName, ["91"]),
      [Products.Gas95]: locale.populate(locale.gasName, ["95"]),
      [Products.Gas97]: locale.populate(locale.gasName, ["97"]),
      [Products.Diesel]: locale.diesel,
      [Products.Lubes]: locale.lubricant,
      [Products.lpg]: locale.lpg,
      [Products.LubeServ]: locale.lubeServ,
    }[productCode] || productCode
  );
};

export const prettifyProductType = (type) => {
  return (
    {
      diesel: "Diesel",
      gas91: "Gas 91",
      gas95: "Gas 95",
      gas97: "Gas 97",
      lubes: "Lubricants",
      lubeserv: "Lubeserv",
      lpg: "LPG"
    }[type] || type
  );
};

export const prettifyAwardCategory = (category) => {
  return (
    {
      "pay-with-liters": "PriceLOCQ",
      locqpay: "PriceLOCQ",
      station: locale.manualAtStation,
      conversion: locale.conversion,
      "pay-with-points": locale.payWithPoints,
      admin: locale.manualAtAdmin,
    }[category] || capitalize(category)
  );
};

export const prettifyVoucherCampaignCurrency = (type) => {
  return (
    {
      [VoucherCampaignCurrency.Peso]: locale.peso,
      [VoucherCampaignCurrency.Liter]: locale.liter,
      [VoucherCampaignCurrency.Points]: locale.points,
    }[type] || ""
  );
};

export const prettifyVoucherStatus = (type) => {
  return (
    {
      [VoucherStatus.Unclaimed]: locale.unclaimed,
      [VoucherStatus.Used]: locale.used,
      [VoucherStatus.Expired]: locale.expired,
      [VoucherStatus.Available]: locale.availableInApp,
      [VoucherStatus.Void]: locale.void,
      [VoucherStatus.Assigned]: locale.assigned,
    }[type] || ""
  );
};

export const prettifyVoucherCampaignType = (type) => {
  return (
    {
      [VoucherCampaignType.Generic]: locale.generic,
      [VoucherCampaignType.Unique]: locale.unique,
    }[type] || ""
  );
};

export const prettifyVoucherCampaignStatus = (type) => {
  return (
    {
      [CampaignStatus.Upcoming]: locale.upcoming,
      [CampaignStatus.Ongoing]: locale.ongoing,
      [CampaignStatus.Ended]: locale.ended,
      [CampaignStatus.Cancelled]: locale.cancelled,
    }[type] || ""
  );
};

export const prettifyOilChangeType = (type) => {
  return (
    {
      [OilChangeType.dieselMonoGrade]: "Diesel Engine Oil Monograde Mineral (40)",
      [OilChangeType.dieselMultiGrade]: "Deomax Multigrade Mineral (15W-40)",
      [OilChangeType.dieselFullySynthetic]: "Diesel Extreme Mako Fully Synthetic (5W-40)",
      [OilChangeType.gasolineMonoGrade]: "Gasoline Engine Oil Monograde Mineral (40)",
      [OilChangeType.gasolineMultiGrade]: "Geo Supreme Multigrade Mineral (20W-40)",
      [OilChangeType.gasolineFullySynthetic]: "Extreme Mako Fully Synthetic (0W-40)",
      [OilChangeType.gasolineFullySynthetic5W40]: "Gasoline Extreme Mako Fully Synthetic (5W-40)",
    }[type] || ""
  );
};

export const prettifyLubeServSite = (lubeServSite) => {
  return (
    {
      [LubeServSite.LubeServAnabuImusCavite]: "LubeServ Anabu Imus Cavite",
      [LubeServSite.LubeServTelabastaganPampanga]: "LubeServ Telabastagan Pampanga",
      [LubeServSite.LubeServCamarinCaloocan]: "Lubeserv Camarin Caloocan - NCR",
      [LubeServSite.LubeServCarmonaCavite]: "Lubeserv Bancal Carmona - Cavite",
      [LubeServSite.LubeServShawBlvd]: "Lubeserv Shaw Boulevard",
    }[lubeServSite] || ""
  );
};

export const prettifySettlementStatus = (type) => {
  return (
    {
      [SettlementStatus.ForProcessing]: locale.forProcessing,
      [SettlementStatus.ForSettlement]: locale.forSettlement,
      [SettlementStatus.Processed]: locale.processed,
    }[type] || ""
  );
};

export const prettifyAccountStatus = (accountStatus) => {
  return (
    {
      [AccountStatus.Active]: locale.active,
      [AccountStatus.Registered]: locale.registered,
      [AccountStatus.Pending]: locale.pending,
      [AccountStatus.Deactivated]: locale.deactivated,
      [AccountStatus.Expired]: locale.expired,
      [AccountStatus.Inactive]: locale.inactive,
      [AccountStatus.UnRegistered]: locale.unregistered,
    }[accountStatus] || ""
  );
};

export const prettifyVipTransactionHistoryStatus = (status) => {
  return (
    {
      [VIPAwardPointsStatusEnum.Success]: locale.success,
      [VIPAwardPointsStatusEnum.Voided]: locale.voided,
    }[status] || ""
  );
};

export const prettifyAuditTrailAction = (type) => {
  return (
    {
      [AuditTrailAction.UpdateFleetDiscount]: "Update Fleet Discount",
      [AuditTrailAction.UpdateFleetRedemptionStaitons]: "Update Fleet Redemption Stations",
      [AuditTrailAction.UpdateFleetRefs]: "Update Fleet Reference Station",
      [AuditTrailAction.ApplyAccountSpecificMovement]: "Apply Account Specific Movement",
    }[type] || ""
  );
};

export const prettifyStationStatus = (type) => {
  return (
    {
      [StationStatus.ForActivation]: "For Activation",
      [StationStatus.Pending]: "Pending",
      [StationStatus.Active]: "Active",
      [StationStatus.Expired]: "Expired",
      [StationStatus.Deactivated]: "Deactivated",
    }[type] || ""
  );
};

export const prettifyOnboardStatus = (type) => {
  return (
    {
      [OnboardStatus.OnBoardLater]: locale.onboardLater,
      [OnboardStatus.OnBoardNow]: locale.onboardNow,
    }[type] || ""
  );
};

export const prettifyPLCStatus = (type) => {
  return (
    {
      [PLCStatus.NotOnboarded]: "Not Onboarded",
      [PLCStatus.Active]: "Active",
      [PLCStatus.Hidden]: "Hidden",
      [PLCStatus.Deactivated]: "Deactivated",
    }[type] || ""
  );
};

export const prettifyPLBStatus = (type) => {
  return (
    {
      [PLBStatus.NotOnboarded]: "Not Onboarded",
      [PLBStatus.Active]: "Active",
      [PLBStatus.Deactivated]: "Deactivated",
    }[type] || ""
  );
};

export const prettifyVIPStatus = (type) => {
  return (
    {
      [VIPStatus.NotOnboarded]: "Not Onboarded",
      [VIPStatus.Active]: "Active",
      [PLCStatus.Hidden]: "Hidden",
      [VIPStatus.Deactivated]: "Deactivated",
    }[type] || ""
  );
};

export const prettifyVoucherOption = (value) => {
  return (
    {
      [VoucherTypeOptions.PesoVoucherCodeOneTime]: "Peso Voucher (One-Time Claim)",
      [VoucherTypeOptions.PesoVoucherCodeAutoSeed]: "Peso Voucher (Auto-Seed)",
      [VoucherTypeOptions.PesoVoucherCodeMultiClaim]: "Peso Voucher (Multi-Claim Generic)",
      [VoucherTypeOptions.PesoDiscountCodeOneTime]: "Peso Discount (One-Time Claim)",
      [VoucherTypeOptions.PesoDiscountCodeAutoSeed]: "Peso Discount (Auto-Seed)",
      [VoucherTypeOptions.PesoDiscountCodeMultiClaim]: "Peso Discount (Multi-Claim Generic)",
    }[value] || ""
  );
};

export const prettifyVoucherType = (value) => {
  return (
    {
      [VoucherType.PesoVoucherCode]: locale.pesoVoucherCode,
      [VoucherType.PesoDiscount]: locale.pesoDiscountOnBuyFuelVoucherCode,
    }[value] || ""
  );
};

export const prettifyVoucherTypeSetting = (value) => {
  return (
    {
      [VoucherTypeSetting.OneTimeClaim]: "One-Time Claim",
      [VoucherTypeSetting.AutoSeed]: "Auto-Seed",
      [VoucherTypeSetting.MultiClaimGeneric]: "Multi-Claim Generic",
    }[value] || ""
  );
};

export const prettifyVoucherStatusType = (value) => {
  return (
    {
      [VoucherType.PesoVoucherCode]: locale.pesoVoucherCode,
      [VoucherType.PesoDiscount]: "Peso Discount",
    }[value] || ""
  );
};

export const prettifyVoucherClaimMethod = (value) => {
  return (
    {
      [ClaimMethod.AutoCashIn]: locale.autoCashIn,
      [ClaimMethod.SeedInVoucherTray]: locale.seedInVoucherTray,
    }[value] || ""
  );
};

export const prettifyVoucherCampaignGroup = (group) => {
  return (
    {
      [VoucherCampaignGroup.SPIAllocation]: "SPI Allocation",
      [VoucherCampaignGroup.SPIAllowance]: "SPI Allowance",
      [VoucherCampaignGroup.SPISuppliers]: "SPI SUPPLIERS",
      [VoucherCampaignGroup.SPIHonorarium]: "SPI HONORARIUM",
      [VoucherCampaignGroup.SPIObTravel]: "SPI OB Travel",
      [VoucherCampaignGroup.Others]: "Others",
    }[group] || ""
  );
};

export const prettifyVoucherCampaignGroupPrefix = (group) => {
  return (
    {
      [VoucherCampaignGroup.SPIAllocation]: "ALC",
      [VoucherCampaignGroup.SPIAllowance]: "ALW",
      [VoucherCampaignGroup.SPISuppliers]: "SUP",
      [VoucherCampaignGroup.SPIHonorarium]: "HON",
    }[group] || ""
  );
};

export const prettifyPlatformType = (type) => {
  return (
    {
      [PlatformType.PLC]: "PLC Only",
      [PlatformType.PLB]: "PLB Only",
      [PlatformType.PLCandPLB]: "PLC and PLB",
      [PlatformType.VIP]: "VIP",
    }[type] || ""
  );
};

export const prettifyReportType = (type) => {
  return (
    {
      [ReportType.CashIn]: "Cashin",
      [ReportType.BuyFuel]: "Buy Fuel",
      [ReportType.Redemption]: "Redemption",
      [ReportType.RedemptionItem]: "Redemption Item",
      [ReportType.PricingHistory]: "Pricing History",
      [ReportType.FuelCode]: "Fuel Code",
      [ReportType.FuelCodes]: "Fuel Codes",
      [ReportType.Mops]: "Mops",
      [ReportType.VoucherClaim]: "Voucher Claim",
      [ReportType.NewCustomerVoucher]: "New Customer Voucher",
      [ReportType.FleetPrices]: "Fleet Prices",
      [ReportType.PriceBuildup]: "Price Buildup",
      [ReportType.PricingChanges]: "Pricing Changes",
      [ReportType.FleetAccount]: "Fleet Account",
      [ReportType.StationAccount]: "Station Account",
      [ReportType.CustomerList]: "Customer List",
      [ReportType.VoucherCampaign]: "Voucher Campaign",
      [ReportType.DiscountCampaign]: "Discount Campaign",
      [ReportType.RfidTopup]: "Rfid Topup",
      [ReportType.Payment]: "Payment",
      [ReportType.KYCSubmission]: "KYC Submission",
      [ReportType.PriceMovement]: "Price Movement",
      [ReportType.SupplierWPP]: "Supplier WPP",
      [ReportType.Settlements]: "Settlements",
      [ReportType.LubeservBookings]: "LubeServ on Wheels",
      [ReportType.StationNegotiatedDiscount]: "DOX Negotiated Discount",
      [ReportType.LubeservOnSite]: "LubeServ on Site",
      [ReportType.LubeservOnWheels]: "LubeServ on Wheels",
      [ReportType.LoyaltyCards]: "Loyalty Cards",
    }[type] || ""
  );
};

export const prettifyFleetAccountType = (value) => {
  return (
    {
      [FleetAccountType.FleetAccount]: "Fleet Account",
      [FleetAccountType.KeyAccount]: "Key Account",
    }[value] || ""
  );
};

export const prettifyUserStatus = (type) => {
  return (
    {
      [UserStatus.Active]: "Active",
      [UserStatus.Deactivated]: "Deactivated",
      [UserStatus.Deleted]: "Deleted",
    }[type] || ""
  );
};

export const prettifyRole = (role) => {
  return (
    {
      [Role.PortalAdmin]: locale.admin,
      [Role.LubeServ]: locale.lubeServe,
      [Role.Marketing]: locale.marketing,
      [Role.Operations]: locale.operations,
      [Role.RetailPricing]: locale.retailPricing,
      [Role.Accounting]: locale.accounting,
      [Role.Finance]: locale.finance,
      [Role.Audit]: locale.audit,
      [Role.GEAD]: locale.gead,
    }[role] || ""
  );
};

export const prettifyCashInChannel = (cashinChannel) => {
  return (
    {
      [CashInChannel.ManualPaymaya]: locale.manualPaymaya,
      [CashInChannel.ManualBankDeposit]: locale.manualBankDeposit,
      [CashInChannel.Card]: locale.card,
      [CashInChannel.OnlineBanking]: locale.onlineBank,
      [CashInChannel.LocqRebate]: locale.locqRebate,
      [CashInChannel.BNPL]: locale.bnpl,
      [CashInChannel.NewCustomerVoucher]: locale.newCustomerVoucher,
      [CashInChannel.Voucher]: locale.voucher,
      [CashInChannel.Referral]: locale.referral,
      [CashInChannel.EWallet]: locale.ewallet,
      [CashInChannel.OTC]: locale.otcBank,
      [CashInChannel.GCash]: locale.gcash,
      [CashInChannel.Billease]: locale.billease,
      [CashInChannel.Cybersource]: locale.cybersource,
      [CashInChannel.Brankas]: locale.brankas,
      [CashInChannel.SevenEleven]: locale.sevenEleven,
      [CashInChannel.BDO]: locale.BDO,
      [CashInChannel.Unionbank]: locale.unionBank,
      [CashInChannel.BPIOnlineBank]: locale.bpi,
      [CashInChannel.Seaoil]: locale.seaoil,
      [CashInChannel.ExpressPay]: locale.expressPay,
      [CashInChannel.ECPay]: locale.ecpay,
      [CashInChannel.HLhuillier]: locale.hlhuillier,
      [CashInChannel.RDPawnshop]: locale.rDPawnshop,
      [CashInChannel.TrueMoney]: locale.trueMoney,
      [CashInChannel.BPI]: locale.BPI,
      [CashInChannel.LocqPromo]: "LOCQ PROMO",
      [CashInChannel.WithholdingTaxRefund]: locale.withholdingTaxRefund,
      [CashInChannel.IntroductoryPromo]: locale.introductoryPromoRebate,
      [CashInChannel.ShopeePay]: locale.shopeePay,
      [CashInChannel.DragonPay]: locale.dragonPay,
      [CashInChannel.Rebate]: locale.rebate,
      [CashInChannel.PayWithPeso]: locale.payWithPeso,
      [CashInChannel.OnlineBankingBrankas]: locale.onlineBankBrankas,
      [CashInChannel.OnlineBankDragonPay]: locale.onlineBankDragonpay,
      [CashInChannel.OnlineBankBPI]: "Online bank (BPI)",
      [CashInChannel.LocqPayDiscount]: locale.locqPayDiscount,
      [CashInChannel.SeaoilReciprocity]: locale.seaoilReciprocity,
      [CashInChannel.VipRedemption]: locale.vipRedemption,
    }[cashinChannel] || ""
  );
};

export const prettifyCashInStatus = (cashInStatus) => {
  return (
    {
      [CashInStatus.Success]: locale.success,
      [CashInStatus.Completed]: locale.completed,
      [CashInStatus.Cancelled]: locale.cancelled,
      [CashInStatus.Pending]: locale.pending,
      [CashInStatus.Failed]: locale.failed,
      [CashInStatus.Expired]: locale.expired,
      [CashInStatus.Voided]: locale.voided,
    }[cashInStatus] || ""
  );
};

export const prettifyCustomerStatus = (type) => {
  return (
    {
      [CustomerStatus.Active]: "Active",
      [CustomerStatus.Deactivated]: "Deactivated - User Initiated",
      [CustomerStatus.Deleted]: "Deleted",
      [CustomerStatus.Blocked]: "Blocked",
    }[type] || ""
  );
};

export const prettifyCurrency = (type) => {
  return (
    {
      [Currency.Peso]: "Peso",
      [Currency.Liter]: "Liter",
      [Currency.Points]: "Points",
    }[type] || ""
  );
};

export const prettifyPayWithLOCQPayStatus = (paymentStatus) => {
  return (
    {
      [PayWithLOCQPayStatus.Success]: locale.success,
      [PayWithLOCQPayStatus.Voided]: locale.voided,
    }[paymentStatus] || ""
  );
};

export const prettifyRedemptionStatus = (redemptionStatus) => {
  return (
    {
      [RedemptionStatus.Success]: locale.success,
      [RedemptionStatus.Voided]: locale.voided,
    }[redemptionStatus] || ""
  );
};

export const prettifyPayWithPesoStatus = (type) => {
  return (
    {
      [PayWithPesoStatus.Pending]: "Pending",
      [PayWithPesoStatus.Success]: "Success",
      [PayWithPesoStatus.Failed]: "Failed",
      [PayWithPesoStatus.Voided]: "Voided",
    }[type] || ""
  );
};

export const prettifyVoidPaymentValue = (value) => {
  return (
    {
      [VoidPaymentValue.WrongAmountInput]: locale.michargedWrongAmountInput,
      [VoidPaymentValue.DidNotIntendToPayViaPeso]: locale.michargedDidNotIntendtoPayViaPeso,
      [VoidPaymentValue.Others]: locale.others,
    }[value] || locale.others
  );
};

export const prettifyVoidTransactionValue = (value) => {
  return (
    {
      [VoidTransactionValue.WrongInputByUser]: locale.mischargeWrongInputByUser,
      [VoidTransactionValue.WrongVolumeInput]: locale.mischargeWrongVolumeInput,
      [VoidTransactionValue.Others]: locale.others,
    }[value] || locale.others
  );
};

export const prettifyDeactivateCardValue = (value) => {
  return (
    {
      [DeactivateCardValue.CustomerRequest]: locale.customerRequest,
      [DeactivateCardValue.FraudRelatedConcern]: locale.fraudRelatedConcern,
      [DeactivateCardValue.Others]: locale.others,
    }[value] || locale.others
  );
};

export const prettifyDispenseType = (dispenseType) => {
  return (
    {
      [DispenseType.Vehicle]: locale.vehicle,
      [DispenseType.DrumContainer]: locale.drumContainer,
    }[dispenseType] || ""
  );
};

export const prettifyVipType = (vipType) => {
  return (
    {
      [VipType.Consumer]: "Consumer",
      [VipType.Rides]: "Rides",
      [VipType.Biz]: "Biz",
      [VipType.Public]: "Public",
    }[vipType] || locale.consumer
  );
};

export const prettifyCustomerRemarks = (remarks) => {
  return (
    {
      [CustomerStatusRemarks.CustomerRequest]: "Customer Request",
      [CustomerStatusRemarks.Fraud]: "Fraud",
      [CustomerStatusRemarks.Others]: "Others",
    }[remarks] || remarks
  );
};

export const prettifyAttributeUploadResponse = (response) => {
  return (
    {
      [AttributeUploadResponse.MissingAttributes]: locale.attributeDoesNotExist,
      [AttributeUploadResponse.MissingUsers]: locale.customerDoesNotExist,
      [AttributeUploadResponse.MissingCohorts]: locale.cohortDoesNotExist,
      [AttributeUploadResponse.UsersUpdated]: locale.usersUpdated,
    }[response] || ""
  );
};

export const prettifyKYCSubmissionStatus = (type) => {
  return (
    {
      [KYCSubmissionStatus.Pending]: "Pending",
      [KYCSubmissionStatus.Approved]: "Approved",
      [KYCSubmissionStatus.Rejected]: "Rejected",
      [KYCSubmissionStatus.Deleted]: "Deleted",
    }[type] || ""
  );
};

export const prettifyRFIDPaymentMethod = (type) => {
  return (
    {
      [RFIDPaymentMethod.Card]: "Card",
      [RFIDPaymentMethod.PesoBalance]: "Peso Balance",
    }[type] || ""
  );
};

export const prettifyRFIDStatus = (type) => {
  return (
    {
      [RFIDStatus.Pending]: "Pending",
      [RFIDStatus.Success]: "Success",
      [RFIDStatus.Failed]: "Failed",
      [RFIDStatus.Voided]: "Voided",
    }[type] || ""
  );
};

export const prettifyRFIDType = (type) => {
  return (
    {
      [RFIDType.Autosweep]: "Autosweep",
      [RFIDType.Easytrip]: "Easytrip",
    }[type] || ""
  );
};
