import { Autocomplete, DateRange, Field, Filter, Button, SearchField } from "components/commons";
import React from "react";
import styles from "./transaction-history-filter.module.scss";
import locale from "localization";
import { SelectStation } from "components/field";
import { AwardTypesEnum } from "enums/award-types";
import { prettifyProductType } from "utils/pretty.utils";
import { Products } from "enums";
import { sortProductsBySku } from "utils/object.utils";

const TransactionHistoryFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  applyResetFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filterCount,
  exportReport,
  exporting,
  resetFilter,
  getUserAccess,
  modifyForm,
}) => {
  const { awardCategory, referenceStation, productCodes } = filterState;

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <Filter
          filterCount={filterCount}
          submit={applyFilter}
          close={resetFilter}
          clear={applyClearFilter}
        >
          <div
            style={{
              padding: "0px 20px",
            }}
          >
            <Field
              label={locale.dateOfTransaction}
              labelPosition={"left"}
              childrenClassName={styles.fieldContent}
            >
              <DateRange
                value={{
                  startDate: filterState.startDate,
                  endDate: filterState.endDate,
                }}
                placeholder="Set Date"
                onChange={(_, { value }) => {
                  const { startDate, endDate } = value;
                  modifyFilter({
                    startDate: startDate,
                    endDate: endDate,
                  });
                }}
              />
            </Field>
            <Field
              label={locale.awardType}
              labelPosition={"left"}
              childrenClassName={styles.fieldContent}
            >
              <Autocomplete
                name="awardCategory"
                value={awardCategory}
                options={[
                  {
                    label: locale.manualAtAdmin,
                    value: AwardTypesEnum.ManualAtAdmin,
                  },
                  {
                    label: locale.manualAtStation,
                    value: AwardTypesEnum.ManualAtStation,
                  },
                  {
                    label: "PriceLOCQ",
                    value: AwardTypesEnum.PriceLOCQ,
                  },
                ]}
                onChange={(name, { value }) => {
                  modifyFilter({ [name]: value });
                }}
              />
            </Field>
            <Field
              label={`SEAOIL ${locale.station}`}
              labelPosition={"left"}
              childrenClassName={styles.fieldContent}
            >
              <SelectStation
                {...referenceStation}
                isMultiple
                disableAllOption
                isPlbOnboarded
                allLabel={locale.station}
                allSelectedText={locale.allStations}
                selectAllLabel={locale.allStations}
                onChange={(_, { value, isAll }) => {
                  modifyFilter({ referenceStation: { value, isAll } });
                }}
              />
            </Field>
            <Field
              label={locale.productType}
              labelPosition={"left"}
              className={styles.filterInput}
              childrenClassName={styles.fieldContent}
            >
              <Autocomplete
                name="productCodes"
                value={productCodes}
                options={sortProductsBySku(
                    Object.values(Products).map(sku => ({ sku }))
                  ).map(({sku}) => sku).map((type) => ({
                  label: prettifyProductType(type),
                  value: type,
                }))}
                onChange={(name, { value }) => {
                  modifyFilter({
                    [name]: value,
                  });
                }}
              />
            </Field>
          </div>
        </Filter>
        <Field className={styles.search}>
          <SearchField
            placeholder={[
              locale.mobileNumber, 
              locale.vipCardNumber, 
              locale.vipTransactionId, 
              locale.orNumber
            ].join(", ")}
            value={searchKey}
            onChange={(_, { value }) => {
              modifySearch(value);
            }}
            onClear={applyClearSearch}
            onEnter={applySearch}
            restrict={false}
          />
        </Field>
        <Field className={styles.downloadButton}>
          {(getUserAccess?.fullAccess || getUserAccess?.exportAccess) && (
            <Button
              startIcon={<span className="icon-download" />}
              exportButton
              onClick={exportReport}
              loading={exporting}
            >
              {locale.export}
            </Button>
          )}
        </Field>
      </div>
    </div>
  );
};

export default TransactionHistoryFilter;
